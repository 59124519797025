import * as React from 'react'
import * as clsx from 'clsx'
import { IconWithText } from '@components/icon-with-text'

interface Props<T> {
  onItemSelection: (item: T) => void
  textRenderer: (item: T) => React.ReactNode
  isItemSelected?: (item: T) => boolean
  items: T[]
  maximumListHeight: number
  title: string
  emptyListElement?: React.ReactNode
}

export const LocalSelectionMapItemsList = <T extends { id: number }>({
  onItemSelection,
  textRenderer,
  isItemSelected,
  items,
  maximumListHeight,
  title,
  emptyListElement,
}: Props<T>): JSX.Element => {
  const hasItems = items.length > 0
  return (
    <div className="local-selection-modal__apartments-list">
      {hasItems && <p className="font-size-sm text-darker-gray">{title}</p>}
      <div>
        <div className="overflow-auto" style={{ minHeight: 290, maxHeight: maximumListHeight }}>
          {items.map((item: T) => (
            <IconWithText
              key={item.id}
              text={textRenderer(item)}
              textClassName="text-darker-gray font-size-sm fw-semi-bold w-100 text-start"
              wrapperClassName={clsx('rounded user-select-none lh-initial py-1 ps-2 pe-3 w-100', {
                'bg-light-blue': isItemSelected?.(item),
              })}
              iconClassName={clsx('text-primary uil-check me-2 opacity-0', {
                'opacity-100': isItemSelected?.(item),
              })}
              onClick={() => onItemSelection(item)}
            />
          ))}

          {!hasItems && emptyListElement}
        </div>
      </div>
    </div>
  )
}

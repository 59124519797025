import * as React from 'react'
import { Apartment } from '@models/reservation'
import { LocalSelectionMapItemsList } from '@modules/accommodation-selection-step/local-selection/local-selection-map/local-selection-map-items-list'
import { sortByPosition } from '@helpers/utils'
import { LocalAllergyFriendlyMark } from '@modules/accommodation-selection-step/local-selection/local-selection-map/local-markers/local-allergy-friendly-mark'
import { LocalWithGardenMark } from '@modules/accommodation-selection-step/local-selection/local-selection-map/local-markers/local-with-garden-mark'
import { LocalForClientWithBenefitMark } from '@modules/accommodation-selection-step/local-selection/local-selection-map/local-markers/local-for-client-with-benefit-mark'
import { isForClientWithBenefit } from '@modules/accommodation-selection-step/local-selection/local-selection-map/use-clickable-resort-map'
import { LocalSelectionEmptyList } from '@modules/accommodation-selection-step/local-selection/local-selection-map/local-markers/local-selection-map-empty-list'
import { AccommodationType } from '@models/app-data'

interface Props {
  maximumListHeight: number
  apartments: Apartment[]
  selectedApartmentId: number | null
  onApartmentSelect: (apartment: Apartment) => void
  accommodationType: AccommodationType | undefined
  resortName: string
}

export const LocalSelectionMapApartmentsList = ({
  maximumListHeight,
  apartments,
  selectedApartmentId,
  onApartmentSelect,
  accommodationType,
  resortName,
}: Props): JSX.Element => {
  const handleApartmentSelection = (apartment: Apartment) => {
    onApartmentSelect(apartment)
  }

  const isApartmentSelected = (apartment: Apartment) => selectedApartmentId === apartment.id

  const renderApartmentText = (apartment: Apartment) => (
    <div className="d-flex align-items-center justify-content-between">
      <span>
        {apartment.apartment_id.startsWith('D') ? 'Domek' : 'Apartament'} numer {apartment.display_name}
      </span>

      <div className="d-flex align-items-center">
        {isForClientWithBenefit(apartment) && <LocalForClientWithBenefitMark apartment={apartment} />}
        {apartment.no_animals && <LocalAllergyFriendlyMark apartment={apartment} />}
        {apartment.has_garden_fixed && <LocalWithGardenMark apartment={apartment} />}
      </div>
    </div>
  )

  const sortedApartments = React.useMemo(() => sortByPosition(apartments), [apartments])

  return (
    <LocalSelectionMapItemsList
      items={sortedApartments}
      onItemSelection={handleApartmentSelection}
      isItemSelected={isApartmentSelected}
      maximumListHeight={maximumListHeight}
      title="Wskaż lokal na mapie lub z listy poniżej:"
      textRenderer={renderApartmentText}
      emptyListElement={
        accommodationType && <LocalSelectionEmptyList accommodationType={accommodationType} resortName={resortName} />
      }
    />
  )
}

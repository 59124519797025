import * as React from 'react'
import { formatPriceShort } from '@helpers/utils'
import { SectionCardTextWithPrice } from '@modules/improvement-step/improvements-sections/section-card-text-with-price'
import { useSelector } from 'react-redux'
import { selectResortDetails } from '@store/selectors/resort-selectors'

export const FeedingPricesBlock = (): JSX.Element | null => {
  const resortDetails = useSelector(selectResortDetails)

  return (
    <>
      {resortDetails.feedings.adult.breakfast_price_brutto && (
        <SectionCardTextWithPrice
          text="Dorośli i młodzież"
          price={formatPriceShort(resortDetails.feedings.adult.breakfast_price_brutto)}
          pricePrefix="od"
          wrapperClassName="mt-xl-4"
        />
      )}
      {resortDetails.feedings.children_5_12.breakfast_price_brutto && (
        <SectionCardTextWithPrice
          text="Dzieci od 5 do 12 lat"
          price={formatPriceShort(resortDetails.feedings.children_5_12.breakfast_price_brutto)}
          pricePrefix="od"
        />
      )}
      {resortDetails.feedings.children_3_4.breakfast_price_brutto && (
        <SectionCardTextWithPrice
          text="Dzieci od 3 do 5 lat"
          price={formatPriceShort(resortDetails.feedings.children_3_4.breakfast_price_brutto)}
          pricePrefix="od"
        />
      )}
      {resortDetails.feedings.baby.breakfast_price_brutto && (
        <SectionCardTextWithPrice
          text="Dzieci do 3 lat"
          price={formatPriceShort(resortDetails.feedings.baby.breakfast_price_brutto)}
          pricePrefix="od"
        />
      )}
    </>
  )
}

import * as React from 'react'
import { AccommodationType } from '@models/app-data'

interface Props {
  accommodationType: AccommodationType
  resortName: string
}

export const LocalSelectionEmptyList = ({ accommodationType, resortName }: Props): JSX.Element => (
  <section className="text-darker-gray font-size-md text-center text-md-start bg-light-azure rounded border mt-lg-5 mt-3 p-3 mb-4 mb-lg-0">
    <p className="fw-semi-bold">Brak dostępnych lokali.</p>
    <p>
      Przepraszamy, w tych dniach wszystkie lokale typu{' '}
      <span className="fw-semi-bold">
        {accommodationType.name} w Holiday Park {resortName}
      </span>{' '}
      są niedostępne.
    </p>

    <p>
      Zachęcamy do kontaktu z naszym <span className="fw-semi-bold">Biurem Obsługi Klienta</span>, które chętnie pomoże
      znaleźć dla Państwa najlepsze możliwe rozwiązanie.
    </p>

    <a href="tel:+48735200800" className="text-decoration-none text-darker-gray fw-semi-bold text-nowrap">
      Telefon: +48 735 200 800
    </a>
    <p className="mt-2 mb-0">Skontaktuj się z nami - postaramy się pomóc!</p>
  </section>
)
